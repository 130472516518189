import Header from './components/header'
import Body from "./components/body"
import Footer from "./components/footer"


function Home() {
  return (
    <>

      <Header></Header>

      <Body></Body>
      <Footer></Footer>

    </>
  )
}

export default Home;

